<template>
  <div>
    <div
      v-if="selectFases && selectFases.length > 0" 
    >
      <b-overlay
        v-if="loadingShow"
        :show="loadingShow"
        rounded="lg"
        opacity="0.6"
        class="mt-2"
      />
      <b-row
        class="no-gutters px-2 height-custom"
      >
        <b-col
          sm="6"
          cols="12"
          class="d-flex justify-content-center align-items-center"
        >
          <fase-grafico
            :dados="selectedFase"
          />
        </b-col>
        <b-col
          sm="6"
          cols="12"
          class="pl-1 pt-lg-1 my-auto pt-sm-0 pt-2"
        >
          <div
            class="d-flex justify-content-center align-items-center"
          >
            <v-select
              v-model="selectedFase"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="selectFases"
              :clearable="false"
              @input="atualizaFaseSelecionadaDoDashboard"
              class="select-custom-fases"
              label="descricao"
              item-text="descricao"
              item-value="descricao"
            >
              <span slot="no-options">Nenhuma fase encontrada.</span>
            </v-select>
          </div>
          <b-row
            class="no-gutters mt-2 text-center px-sm-0 px-2"
          >
            <b-col
              cols="6"
            >
              <div
                class="subtitle-font"
              >
                Participantes
              </div>
              <div
                class="porcentage-font"
              >
                {{ selectedFase.participantes }}
              </div>
            </b-col>
            <b-col
              cols="6"
            >
              <div
                class="subtitle-font"
              >
                Recusas
              </div>
              <div
                class="porcentage-font"
              >
                {{ selectedFase.recusas }}
              </div>
            </b-col>
          </b-row>
          <b-row
            class="no-gutters text-center mt-1 px-sm-0 px-2"
          >
            <b-col
              cols="6"
            >
              <div
                class="subtitle-font"
              >
                Pendentes
              </div>
              <div
                class="porcentage-font"
              >
                {{ selectedFase.pendentes }}
              </div>
            </b-col>
            <b-col
              cols="6"
            >
              <div
                class="subtitle-font"
              >
                Finalizados
              </div>
              <div
                class="porcentage-font"
              >
                {{ selectedFase.respostas + selectedFase.recusas }}
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div
      v-else
      class="height-custom d-flex align-items-center justify-content-center"
    >
      <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BOverlay
} from "bootstrap-vue";
import CardSemDados from "@/views/components/custom/dashboard-saude-mental/dass-21/CardSemDados.vue";
import FaseGrafico from "@/views/components/custom/dashboard-saude-mental/dass-21/FaseGrafico.vue";
import vSelect from "vue-select"

export default {
  components: {
    CardSemDados,
    BRow,
    BCol,
    BOverlay,
    FaseGrafico,
    vSelect
  },
  props: {
    dados: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loadingShow: true,
      validarCardSemDados: {
        verificadorVazio: true,
        tipagemErro: 0,
      },
      selectFases: null,
      selectedFase: null,
      graphSeries: []
    };
  },
  mounted() {
    this.selectFases = this.$props.dados.allFases;
    this.selectedFase = this.$props.dados.selectedFase;
    this.loadingShow = false
  },
  methods: {
    atualizaFaseSelecionadaDoDashboard(objectFase){
      this.$emit("atualizaFaseSelecionadaDoDashboard", objectFase);
    }
  }
};
</script>
<style scoped>
.height-custom{
  height: 212px;
}
.select-custom-fases{
  min-width: 130px;
}
.select-custom-fases::v-deep .vs__dropdown-toggle{
  border: 1px solid #7030A0;
  justify-content: space-between;
  align-items: center;
}
.select-custom-fases::v-deep .vs__selected-options{
  padding: 0;
  flex-basis: inherit;
  flex-grow: initial;
  padding-left: 5px;
}
.select-custom-fases::v-deep .vs__search{
  margin: 0;
  padding: 0;
  max-height: 0;
  max-width: 0;
}
.select-custom-fases::v-deep .vs__selected{
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #7030A0 !important;
}
.select-custom-fases.vs--single.vs--open::v-deep .vs__selected{
  position: relative;
}
.select-custom-fases::v-deep .feather-chevron-down{
  color: #7030A0;
}
.subtitle-font{
  color: #B9B9C3;
  font-size: 12px;
  font-weight: 400;
}
.porcentage-font{
  color: #5E5873;
  font-size: 18px;
  font-weight: 700;
}
@media (min-width: 1600px) {
  .select-custom-fases::v-deep .vs__selected{
    font-size: 13px;
  }
  .subtitle-font{
    font-size: 14px;
  }
  .porcentage-font{
    font-size: 20px;
  }
}
@media (max-width: 991px) {
  .subtitle-font{
    font-size: 10px;
  }
  .porcentage-font{
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .height-custom{
    height: 100%;
    margin-bottom: 2rem;
  }
  .subtitle-font{
    font-size: 12px;
  }
  .porcentage-font{
    font-size: 18px;
  }
}
</style>
